import {
  GET_SSHEPHERD_SESSION_LIST,
  GET_SSHEPHERD_SESSION_DETAIL,
  GET_SSHEPHERD_SESSION_CHARTS,
  GET_SSHEPHERD_DASHBOARD_LIST,
  GET_SSHEPHERD_DEVICE_LIST,
  GET_SSHEPHERD_ROLE_LIST,
  GET_SSHEPHERD_USER_LIST,
  GET_SSHEPHERD_GROUP_LIST,
  GET_SSHEPHERD_USER_ROLES,
  GET_SSHEPHERD_ROLE_USERS,
  SET_SSHEPHERD_SELECTED_SESSION,
  SET_SSHEPHERD_SELECTED_GROUP,
  SET_SSHEPHERD_SELECTED_ROLE,
  SET_SSHEPHERD_SELECTED_USER,
  SET_SSHEPHERD_SELECTED_HOST,
  SET_SSHEPHERD_SELECTED_VIEWDESCRIPTION,
  SET_SSHEPHERD_SESSION_TIME_FILTER,
  SET_SSHEPHERD_SESSION_TIMERANGE_FILTER,
  SET_SSHEPHERD_SESSION_TYPE_FILTER,
  SET_SSHEPHERD_SESSION_LIVE_FILTER,
  ATTACH_SSHEPHERD_LIVE_SESSION,
  SSHEPHERD_LIVE_SESSION_UPDATED,
  SET_SSHEPHERD_SELECTED_SESSION_SHOW_DETAIL,
  SET_SSHEPHERD_SELECTED_SESSION_SHOW_VIDEO,
  RESET_SSHEPHERD_STATE,
  CREATE_NEW_SSHEPHERD_GROUP,
  CREATE_NEW_SSHEPHERD_GROUP_START,
  SSHEPHERD_REFRESH_TIMER,
  GET_SSHEPHERD_AUTH_PROVIDER,
  GET_SSHEPHERD_AUTH_CONFIG,
  SET_SSHEPHERD_AUTH_CONFIG,
  SET_SSHEPHERD_DETECTING_AUTH_PROVIDER,
  GET_SSHEPHERD_LICENSE_CONFIG,
  SET_SSHEPHERD_LICENSE_CONFIG,
  GET_SSHEPHERD_REGISTRATION_KEYS,
  SET_SSHEPHERD_SELECTED_REGISTRATION_KEY,
  GENERATE_SSHEPHERD_REGISTRATION_KEY,
  //DELETE_SSHEPHERD_REGISTRATION_KEY,
  CLEAR_SSHEPHERD_GENERATED_REGISTRATION_KEY,
  SET_SSHEPHERD_FORCE_STANDARD_AUTH_LOGIN,
  GET_SSHEPHERD_SECURITY,
  SSHEPHERD_AUTHORIZATION_DIALOG_OPEN,
  SSHEPHERD_AUTHORIZATION_DIALOG_CLOSE,
  SET_ONESHOT_AUTH_WINDOW,
  GET_SSHEPHERD_SELECTED_HOST_CONFIG,
  SET_EFFECTIVE_TOKEN_MAX_AGE,
  SET_API_TOKEN,
} from "../../shared/constants/ActionTypes";

import { defaultAuthConfig } from "../../shared/constants/AppConst";

const initialState = {
  taskList: [],
  folderList: [],
  labelList: [],
  priorityList: [],
  statusList: [],
  staffList: [],
  totalTasks: null,
  todoDrawer: false,
  selectedTask: null,
  sessionList: [],
  hostList: [],
  dashboardList: [],
  roleList: [],
  userList: [],
  groupList: [],
  userRoles: [],
  selectedUser: null,
  roleUsers: [],
  liveHostIdList: [],
  selectedSession: { id: null },
  selectedSessionDetail: null,
  showSelectedSessionDetail: false,
  selectedSessionVideo: null,
  showSelectedSessionVideo: false,
  selectedRole: null,
  selectedGroup: null,
  selectedGroupName: null,
  selectedRole: null,
  selectedRoleName: null,
  selectedUser: null,
  selectedUserName: null,
  selectedHost: null,
  selectedHostName: null,
  selectedHostConfig: null,
  selectedHostId: null,
  selectedUsernameForRoles: null,
  selectedRoleNameForUsers: null,
  selectedRegistrationKey: null,
  sessionCharts: {
    past24Hours: [],
    pastWeek: [],
    pastMonth: [],
    longRunning: [],
    totalPast24Hours: 0,
    totalPastWeek: 0,
    totalPastMonth: 0,
    totalLongRunning: 0,
  },
  sessionTimeFilter: -1,
  sessionTimeFilterStart: null,
  sessionTimeFilterEnd: null,
  sessionTimeFilterOnlyLive: null,
  sessionTypeFilter: null,
  sessionLiveFilter: null,
  liveSessionId: null,
  liveSessionData: null,
  liveSessionSocket: null,
  groupCreated: false,
  refreshViewValue: false,
  registrationKeys: [],
  generatedRegistrationKey: null,
  licenseConfig: { expires: "Missing License" },
  authProvider: -1,
  forceStandardLogin: false,
  detectingAuthProvider: true,
  authConfig: defaultAuthConfig,
  sshepherdSecurity: null,
  sshepherdAuthorizationDialogOpen: null,
  sshepherdAuthorizationDialogCallback: null,
  oneshotAuthWindow: null,
  effectiveTokenMaxAge: 0,
  apiToken: null,
};

const defaultState = { ...initialState }; // create a copy of the initial state so we can reset back to it

const sshepAppReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SSHEPHERD_SESSION_LIST:
      return {
        ...state,
        sessionList: action.payload.recordings,
        totalSessions: action.payload.recordings.length,
        liveSessions: action.payload.recordings.filter((r) => !r.ended_on)
          .length,
        liveHostIdList: [
          ...new Set(
            action.payload.recordings
              ?.filter((r) => r.isLive)
              .map((s) => s.host?.id)
          ),
        ],
      };

    case GET_SSHEPHERD_SESSION_CHARTS:
      return {
        ...state,
        sessionCharts: action.payload,
      };

    case GET_SSHEPHERD_DEVICE_LIST:
      return {
        ...state,
        hostList: action.payload.agents,
        totalHosts: action.payload.agents.length,
      };

    case GET_SSHEPHERD_DASHBOARD_LIST:
      return {
        ...state,
        dashboardList: action.payload.agents_dashboard,
        //dashboardList: action.payload,

        //totalDashboard: action.payload.agents_dashboard.length,
      };

    case GET_SSHEPHERD_ROLE_LIST:
      return {
        ...state,
        roleList: action.payload.roles,
        totalRoles: action.payload.roles.length,
      };

    case GET_SSHEPHERD_USER_LIST:
      return {
        ...state,
        //userList: action.payload.users,
        // ensure we have a list of user objects by distinct email
        userList: [
          ...new Map(
            action.payload.users.map((item) => [item["email"], item])
          ).values(),
        ], // filter out duplicates
        totalUsers: action.payload.users.length,
      };

    case GET_SSHEPHERD_GROUP_LIST:
      return {
        ...state,
        groupList: action.payload.groups,
        totalGroups: action.payload.groups.length,
      };

    case GET_SSHEPHERD_USER_ROLES:
      return {
        ...state,
        selectedUsernameForRoles: action.payload.userEmail,
        userRoles: action.payload.userRoles.roles,
      };

    case GET_SSHEPHERD_ROLE_USERS:
      return {
        ...state,
        selectedRoleNameForUsers: action.payload.rolename,
        roleUsers: action.payload.roleUsers,
      };

    case GET_SSHEPHERD_SESSION_DETAIL:
      return {
        ...state,
        liveSessionId: null, // not a live session
        liveSessionData: null, // not a live session
        selectedSessionDetail: action.payload,
      };

    case SET_SSHEPHERD_SELECTED_SESSION:
      if (
        !initialState.selectedSession ||
        !action.payload ||
        initialState.selectedSession.id != action.payload.id
      )
        return {
          ...state,
          selectedSession: action.payload,
        };
      else return state;

    case SET_SSHEPHERD_SELECTED_SESSION_SHOW_DETAIL:
      return {
        ...state,
        showSelectedSessionDetail: action.payload,
      };

    case SET_SSHEPHERD_SELECTED_SESSION_SHOW_VIDEO:
      return {
        ...state,
        showSelectedSessionVideo: action.payload,
      };

    case SET_SSHEPHERD_SELECTED_GROUP:
      return {
        ...state,
        selectedGroup: action.payload,
        selectedGroupName: action.payload ? { ...action.payload }.name : null,
      };

    case SET_SSHEPHERD_SELECTED_ROLE:
      return {
        ...state,
        selectedRole: action.payload,
        selectedRoleName: action.payload ? { ...action.payload }.name : null,
      };

    case SET_SSHEPHERD_SELECTED_USER:
      return {
        ...state,
        selectedUser: action.payload,
        selectedUserName: action.payload ? { ...action.payload }.email : null,
      };

    case SET_SSHEPHERD_SELECTED_HOST:
      return {
        ...state,
        selectedHost: action.payload,
        selectedHostName: action.payload
          ? { ...action.payload }.hostname
          : null,
        selectedHostId: action.payload ? { ...action.payload }.id : null,
      };

    case SET_SSHEPHERD_SELECTED_VIEWDESCRIPTION:
      return {
        ...state,
        selectedViewDescription: action.payload,
      };

    case SET_SSHEPHERD_SESSION_TIME_FILTER:
      return {
        ...state,
        sessionTimeFilter: action.payload.timeFilter,
        sessionTimeFilterStart: action.payload.startTime,
        sessionTimeFilterEnd: null,
        sessionTimeFilterOnlyLive: action.payload.onlyLive,
      };
      break;

    case SET_SSHEPHERD_SESSION_TIMERANGE_FILTER:
      return {
        ...state,
        sessionTimeFilterStart: action.payload.startTime,
        sessionTimeFilterEnd: action.payload.endTime,
      };
      break;

    case SET_SSHEPHERD_SESSION_TYPE_FILTER:
      return {
        ...state,
        sessionTypeFilter: action.payload.sessionType,
      };
      break;

    case SET_SSHEPHERD_SESSION_LIVE_FILTER:
      return {
        ...state,
        sessionLiveFilter: action.payload.isLive,
      };
      break;

    case ATTACH_SSHEPHERD_LIVE_SESSION:
      return {
        ...state,
        liveSessionId: action.payload.sessionId,
        liveSessionSocket: action.payload.liveSessionSocket,
      };
      break;

    case SSHEPHERD_LIVE_SESSION_UPDATED:
      return {
        ...state,
        liveSessionData: action.payload.socketData,
      };
      break;

    case RESET_SSHEPHERD_STATE:
      return { ...defaultState };

    case CREATE_NEW_SSHEPHERD_GROUP_START:
      return {
        ...state,
        groupCreated: false,
      };
      break;

    case CREATE_NEW_SSHEPHERD_GROUP:
      return {
        ...state,
        groupCreated: true,
      };
      break;

    case SSHEPHERD_REFRESH_TIMER:
      return {
        ...state,
        refreshViewValue: !state.refreshViewValue,
      };
      break;

    case GET_SSHEPHERD_AUTH_PROVIDER:
      return {
        ...state,
        authProvider: action.payload,
      };
      break;

    case GET_SSHEPHERD_AUTH_CONFIG:
      return {
        ...state,
        authConfig: action.payload,
      };
      break;

    case SET_SSHEPHERD_AUTH_CONFIG:
      return {
        ...state,
        authConfig: action.payload,
      };
      break;

    case SET_SSHEPHERD_DETECTING_AUTH_PROVIDER:
      return {
        ...state,
        detectingAuthProvider: action.payload,
      };
      break;

    case GET_SSHEPHERD_LICENSE_CONFIG:
      return {
        ...state,
        licenseConfig: action.payload,
      };
      break;

    case SET_SSHEPHERD_LICENSE_CONFIG:
      return {
        ...state,
        licenseConfig: action.payload,
      };
      break;

    case GET_SSHEPHERD_REGISTRATION_KEYS:
      return {
        ...state,
        registrationKeys: action.payload,
        totalRegistrationKeys: action.payload.length,
      };
      break;

    case SET_SSHEPHERD_SELECTED_REGISTRATION_KEY:
      return {
        ...state,
        selectedRegistrationKey: action.payload,
      };

    case GENERATE_SSHEPHERD_REGISTRATION_KEY:
      return {
        ...state,
        generatedRegistrationKey: action.payload,
      };

    case CLEAR_SSHEPHERD_GENERATED_REGISTRATION_KEY:
      return {
        ...state,
        generatedRegistrationKey: null,
      };

    case SET_SSHEPHERD_FORCE_STANDARD_AUTH_LOGIN:
      return {
        ...state,
        forceStandardLogin: action.payload,
      };

    case GET_SSHEPHERD_SECURITY:
      return {
        ...state,
        sshepherdSecurity: action.payload,
      };

    case SSHEPHERD_AUTHORIZATION_DIALOG_OPEN:
      return {
        ...state,
        sshepherdAuthorizationDialogOpen: true,
        sshepherdAuthorizationDialogCallback: action.payload,
      };

    case SSHEPHERD_AUTHORIZATION_DIALOG_CLOSE:
      return {
        ...state,
        sshepherdAuthorizationDialogOpen: false,
      };

    case SET_ONESHOT_AUTH_WINDOW:
      return {
        ...state,
        oneshotAuthWindow: action.payload,
      };

    case GET_SSHEPHERD_SELECTED_HOST_CONFIG:
      return {
        ...state,
        selectedHostConfig: action.payload,
      };

    case SET_EFFECTIVE_TOKEN_MAX_AGE:
      return {
        ...state,
        effectiveTokenMaxAge: action.payload,
      };

    case SET_API_TOKEN:
      return {
        ...state,
        apiToken: action.payload,
      };

    default:
      return state;
  }
};
export default sshepAppReducer;
