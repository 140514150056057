import React from "react";
import metadata from "../../metadata.json";
import moment from "moment";

export const renderMetadata = () => {
  let thisYear = moment().year();
  thisYear = thisYear >= 2024 ? thisYear : 2024;
  let copyrightNotice = "© " + thisYear + " FullArmor Corporation";

  return (
    <>
      <div style={{ padding: "10px 20px 0px 20px", width: "100%" }}>
        {"SSHepherd® Server " +
          (metadata
            ? `${metadata?.version} (${metadata?.buildNumber}) ${metadata?.buildTag}`
            : "")}
      </div>
      <div style={{ padding: "0px 20px 10px 20px", width: "100%" }}>
        {copyrightNotice}
      </div>
    </>
  );
};
