export const ssoConfig = {
  baseURL: "https://sshepherd.us",
  //baseURL: "https://saml.sshepherd.io/",
  //baseURL: "https://shepsaml.ngrok.io",
};

export const authRole = {
  admin: ["admin", "user"],
  user: ["user"],
};

export const defaultUser = {
  displayName: "Administrator",
  email: "demo@example.com",
  token: "access-token",
  role: "user",
  photoURL: "https://via.placeholder.com/150",
};
export const initialUrl = "/recordings"; // this url will open after login
export const fallbackUrl = "/hostsdashboard";

export const authProviders = {
  PASSWORD: 1,
  SAML: 2,
  OIDC: 3,
  LDAP: 4,
};

export const OS = {
  Linux: 1,
  Windows: 2,
  MacOS: 3,
};

export const LogLevel = {
  INFO: "INFO",
  WARNING: "WARNING",
  ERROR: "ERROR",
  DEBUG: "DEBUG",
};

export const defaultAuthConfig = {
  //authConfig: {
  auth_type: 1,
  saml_config: {
    strict: false,
    debug: false,
    idp: {
      entityId: "",
      singleLogoutService: {
        binding: "urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect",
        url: "",
      },
      singleSignOnService: {
        binding: "urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect",
        url: "",
      },
      x509cert: "",
    },
    sp: {
      entityId: "https://" + window.location.host + "/saml/metadata",
      assertionConsumerService: {
        url: "https://" + window.location.host + "/saml/acs",
        binding: "urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST",
      },
      singleLogoutService: {
        url: "https://" + window.location.host + "/saml/sls",
        binding: "urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect",
      },
      NameIDFormat: "urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress",
      x509cert: "",
      privateKey: "",
    },
    security: {
      nameIdEncrypted: false,
      authnRequestsSigned: false,
      logoutRequestSigned: false,
      logoutResponseSigned: false,
      signMetadata: false,
      wantMessagesSigned: false,
      wantAssertionsSigned: false,
      wantNameId: false,
      wantNameIdEncrypted: false,
      wantAssertionsEncrypted: false,
      allowSingleLabelDomains: false,
      lowercase_urlencoding: false,
      rejectDeprecatedAlgorithm: true,
      signatureAlgorithm: "",
      digestAlgorithm: "",
    },
    contactPerson: {
      technical: {
        givenName: "",
        emailAddress: "",
      },
      support: {
        givenName: "",
        emailAddress: "",
      },
    },
    organization: {
      "en-US": {
        name: "SSHepherdSSO",
        displayname: "SSHepherd Authentication",
        url: "https://www.fullarmor.com",
      },
    },
  },

  oidc_config: {
    client_id: "", //"d5abd6bf-efba-4b2d-88eb-7f28d66192ac",
    client_secret: "", //"jdK8Q~BG3~fDWBj8z1J9bn3WW~9eQe9PH8EQxaMF",
    scope: ["profile", "email", "openid"],
    well_known_url: "https://", //https://login.microsoftonline.com/63288738-1a68-4309-8ce4-522f580655b6/v2.0/.well-known/openid-configuration",
  },

  ldap_config: {
    // Required
    ldap_host: "",
    ldap_bind_user: "",
    ldap_bind_password: "",

    // Optional
    ldap_base_dn: "",
    ldap_use_ssl: null,
    ldap_user_object_filter: "",
    ldap_group_object_filter: "",
  },
  //},
};

/*
export const defaultOIDCConfig = {
  client_id: "",
  client_secret: "",
  scope: ["profile", "email", "openid"],
  well_known_url: "https://",
};
*/

export const timeRangeFilter = {
  Past24Hours: 0,
  PastWeek: 1,
  PastMonth: 2,
  All: 3,
  LongRunning: 4,
};
