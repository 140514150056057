import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onTimerElapsed, onJWTAuthSignout } from "../../../redux/actions";

// Global Timer Component.  Creates a timer globally in the app that all views can use
const TimerComponent = (props) => {
  const dispatch = useDispatch();
  const theApp = useSelector(({ sshepApp }) => sshepApp);

  let updateLiveSessionsDurationTimer = null;
  let tokenExpirationTimer = null;
  const refreshIntervalSecs = 120;

  // View Refresh Timer
  useEffect(() => {
    updateLiveSessionsDurationTimer = setInterval(() => {
      dispatch(onTimerElapsed());
    }, refreshIntervalSecs * 1000); // refresh the active view every 5 minutes

    return () => {
      // Anything in here is fired on component unmount.
      clearInterval(updateLiveSessionsDurationTimer);
    };
  }, []);

  // Token Expiration Timer
  useEffect(() => {
    if (theApp.effectiveTokenMaxAge > 0) {
      // of we have a max age for token, set a timer
      clearTimeout(tokenExpirationTimer);
      tokenExpirationTimer = setTimeout(() => {
        dispatch(onJWTAuthSignout());
      }, theApp.effectiveTokenMaxAge * 1000); // effectiveTokenMaxAge is given in seconds, so multiply by 1000 to get msecs for the timer
    }
  }, [theApp.effectiveTokenMaxAge, theApp.apiToken]);

  return null;
};

export default TimerComponent;
